/* eslint  react/forbid-prop-types: 0 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import Title from './Title/Title';
import Footer from './Footer/Footer';
import { PortfolioProvider } from '../context/context';
import { heroData, footerData } from '../data/data';
import Hero2 from './Hero/Hero2';

function Category({ category, pageDescription, nodes }) {
  const [hero, setHero] = useState({});
  const [footer, setFooter] = useState({});

  useEffect(() => {
    setHero({ ...heroData });
    setFooter({ ...footerData });
  }, []);

  return (
    <PortfolioProvider value={{ hero, footer }}>
      <Hero2 />
      <Container>
        <div className="project-wrapper">
          <Title title={`カテゴリー - ${category}`} />
          <p>{pageDescription}</p>
          <a href="/blog/">投稿されたブログの一覧を見る</a>
          <div className="project-wrapper__text" style={{ textAlign: 'center' }}>
            {nodes.map(({ frontmatter: { title, description, slug, date } }) => (
              <div className="blog-post-content">
                <a href={slug} key={slug}>
                  <h3 className="project-wrapper__text-title">{title}</h3>
                </a>
                <p>{description}</p>
                <p style={{ color: '#aaa' }}>{`公開日: ${date}`}</p>
              </div>
            ))}
          </div>
        </div>
      </Container>
      <Footer />
    </PortfolioProvider>
  );
}

Category.propTypes = {
  category: PropTypes.string,
  pageDescription: PropTypes.string,
  nodes: PropTypes.object,
};

export default Category;
