/* eslint  react/forbid-prop-types: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Header from '../components/Header';
import Category from '../components/Category';
import { headData } from '../data/data';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

const CategoriesTemplate = ({ data }) => {
  const {
    allMarkdownRemark: { nodes },
  } = data;
  const { title: siteTitle, lang, keyword, siteUrl, ogpUrl } = headData;
  const { category } = nodes[0].frontmatter;
  const description = `${category} に関するブログ投稿の一覧です`;

  return (
    <>
      <Header
        title={siteTitle}
        titleText={`カテゴリー - ${category} | ${siteTitle}`}
        lang={lang}
        description={description}
        keyword={keyword}
        baseUrl={siteUrl}
        siteUrl={`${siteUrl}/categories/${category}/`}
        ogpUrl={ogpUrl}
      />
      <Category category={category} pageDescription={description} nodes={nodes} />
    </>
  );
};

export const pageQuery = graphql`
  query($category: String!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { category: { eq: $category } } }
    ) {
      nodes {
        frontmatter {
          title
          description
          slug
          category
          date(formatString: "YYYY/MM/DD")
        }
      }
    }
  }
`;

CategoriesTemplate.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.object,
  }),
};

export default CategoriesTemplate;
